.wrapper{

}

.contentWrapper{
}

.merchantInfo{
    background: #F3F3F3

}
.merchantInfo h5{
    /* color: white !important; */
}
.numberInput input{
    background-color: white;
}
.contentFooter{
}

.contentFooter button{
    color: black;
    background-color: #F3F3F3!important;
}