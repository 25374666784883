.wrapper{

}

.contentWrapper{
    background: #CF2253
}

.merchantInfo{
    background: #E11670;
}
.merchantInfo h5{
    color: white !important;
}
.numberInput input{
    background-color: white;
}
.contentFooter{
    color: white
}

.contentFooter button{
    background-color: #990000!important;
}